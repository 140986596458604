
import Vue from "vue";
import ImageOrIcon from "./fields/ImageOrIcon.vue";
import TitleAddressIcon from "./fields/TitleAddressIcon.vue";
import ActionButton from "./fields/ActionButton.vue";
import CardInfo from "./fields/CardInfo.vue";

export default Vue.extend({
  name: "EntityCard",
  props: {
    entity: Object as () => WidgetOptions["entity"],
    emitter: Object as () => WidgetOptions["emitter"],
    isCompactView: Boolean,
    toast: Object as () => WidgetOptions["toast"],
    mainAction: Object as () => WidgetOptions["mainAction"],
    secondAction: Object as () => WidgetOptions["mainAction"],
    meta: Object as () => WidgetOptions["meta"],
  },
  data() {
    return {
      compactView: this.isCompactView,
      components: {
        ImageOrIcon,
        TitleAddressIcon,
        ActionButton,
        CardInfo,
      },
    };
  },
  inject: ["sideEffects"],
  computed: {
    actions(): Record<ActionNames, () => void> {
      return {
        mainAction: this.mainAction ? this.mainAction.callback : () => {},
        secondAction: this.secondAction ? this.secondAction.callback : () => {},
        //@ts-ignore
        expand: this.sideEffects.includes("card-on-map")
          ? false
          : () => {
              this.compactView = !this.compactView;
            },
      };
    },
    director(): Record<string, any> {
      if (this.meta.viewCard) {
        const { fields, cardClass, cardStyle } = this.meta.viewCard;
        return {
          fields,
          cardClass,
          cardStyle,
        };
      }
      return {};
    },
  },
  methods: {
    isArray: (arr: any) => {
      return Array.isArray(arr);
    },
    collapse() {
      this.compactView = true;
    },
    expand() {
      this.compactView = false;
    },
  },
  mounted() {
    if (this.emitter && typeof this.emitter.on === "function") {
      this.emitter.on("collapse", this.collapse);
      this.emitter.on("expand", this.expand);
    }
  },
  destroyed() {
    if (this.emitter && typeof this.emitter.off === "function") {
      this.emitter.off("collapse", this.collapse);
      this.emitter.off("expand", this.expand);
    }
  },
});
