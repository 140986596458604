
import Vue from "vue";
export default Vue.extend({
  name: "ActionButton",
  props: {
    entity: Object,
    label: String,
    actionClass: String,
    actionStyle: String,
    callback: Function,
  },
});
