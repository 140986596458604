
import Vue from "vue";
import EntityCard from "./components/EntityCard.vue";
import { repositoryGetter, TUnionRepo } from "@netvision/lib-api-repo";

export default Vue.extend({
  name: "App",
  components: {
    EntityCard,
  },
  data() {
    const props = (this.$parent?.props as WidgetOptions) || {};
    return {
      props,
      $api: {},
    };
  },
  beforeMount() {
    // Mixing locales
    //@ts-ignore
    this.$i18n.vm.messages[this.$i18n.locale] = {
      ...this.$i18n.messages[this.$i18n.locale],
      ...this.props.meta.localeRu,
    };
    this.$api = repositoryGetter(this.$parent?.props?.lib?.name) as TUnionRepo;
  },
  provide(): any {
    const props = (this.$parent?.props as WidgetOptions) || {};
    return {
      sideEffects: props.sideEffects || [],
      $api: this.$api,
    };
  },
});
