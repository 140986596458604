
import Vue from "vue";

export default Vue.extend({
  name: "CopyLocation",
  props: {
    entity: Object,
    title: String,
    fieldClass: String,
    fieldStyle: String,
    fields: Array,
    isCompactView: Boolean,
    options: Object,
    toast: Object,
  },
  methods: {
    copyToClipboard() {
      let inputField = this.$refs[
        `coords-${this.entity.id}`
      ] as HTMLInputElement;
      inputField = Array.isArray(inputField) ? inputField[0] : inputField;
      inputField.value = this.entity.location;
      inputField.select();
      document.execCommand("copy");
      this.toast.add({
        severity: "info",
        summary: this.$t("coordinatesCopied"),
        detail: this.entity.location,
        life: 3000,
      });
    },
  },
});
