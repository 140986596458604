
import Vue from "vue";
import FullAddress from "./FullAddress.vue";
import InfoRow from "./InfoRow.vue";
import CopyLocation from "./CopyLocation.vue";
import RefsRow from "./RefsRow.vue";

type Field = { title: string; type: string };

export default Vue.extend({
  name: "CardInfo",
  props: {
    entity: Object,
    title: String,
    toast: Object,
    fieldClass: String,
    fieldStyle: String,
    fields: Array as () => Field[],
    isCompactView: Boolean,
    options: Object,
  },
  data() {
    return {
      components: {
        FullAddress,
        InfoRow,
        CopyLocation,
        RefsRow,
      } as Record<string, any>,
    };
  },
});
