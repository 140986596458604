
import Vue from "vue";
import { TUnionRepo } from "@netvision/lib-api-repo";

const butchRequestObserver = {
  _idsList: [] as { id: string; type: string }[],
  _timeoutId: 0 as any,
  addIds(ids: string[], type: string, api: TUnionRepo) {
    this._idsList = [...this._idsList, ...ids.map((id) => ({ id, type }))];
    clearTimeout(this._timeoutId);
    this._timeoutId = setTimeout(async () => {
      try {
        const { results } = (await this._fetcher(api)) as unknown as {
          results: { id: string; title: string }[];
        };
        const resultsMap = results.reduce(
          (acc, { id, title }) => ({ ...acc, [id]: title }),
          {} as Record<string, string>
        );
        this._subscribers.forEach((subscriber) => {
          subscriber(resultsMap);
        });
        this._subscribers = [];
        this._idsList = [];
      } catch (error) {
        console.error(error);
      }
    }, 300);
  },
  _fetcher(api: TUnionRepo) {
    if (!("batchQuery" in api)) {
      throw new Error("batchQuery method is required in this API");
    }

    return api.batchQuery({
      limiter: { limit: 1000 },
      filter: {
        entities: this._idsList,
      },
    });
  },
  _subscribers: [] as ((result: any) => void)[],
  then(callback: (result: any) => void) {
    this._subscribers.push(callback);
  },
};

export default Vue.extend({
  name: "RefsRow",
  props: {
    entity: Object,
    title: String,
    fieldClass: String,
    fieldStyle: String,
    options: Object,
  },
  data() {
    return {
      refsTitlesMap: {} as Record<string, string>,
    };
  },
  computed: {
    refTitles(): string[] {
      if (!Array.isArray(this.entity[this.title])) return [];
      return this.entity[this.title].map(
        (refId: string) => this.refsTitlesMap[refId]
      );
    },
  },
  inject: ["$api"],
  mounted() {
    if (!window.butchRequestObserver) {
      window.butchRequestObserver = butchRequestObserver;
    }
    const refs = Array.isArray(this.entity[this.title])
      ? this.entity[this.title]
      : [];
    window.butchRequestObserver.addIds(
      refs,
      this.options.entityType,
      this.$api
    );
    window.butchRequestObserver.then((result) => {
      if (result) {
        this.refsTitlesMap = result;
      }
    });
  },
});
