
import Vue from "vue";

export default Vue.extend({
  name: "FullAddress",
  props: {
    entity: Object,
    title: String,
    fieldClass: String,
    fieldStyle: String,
    fields: Array,
    options: Object,
    toast: Object,
  },
  computed: {
    address(): string[] {
      if (!this.entity[this.title]) return [];
      return (this.options["addressSubFields"] || [])
        .map((field: string) => this.entity[this.title][field])
        .filter((e: any) => e);
    },
  },
});
