
import Vue from "vue";

export default Vue.extend({
  name: "InfoRow",
  props: {
    entity: Object,
    title: String,
    fieldClass: String,
    fieldStyle: String,
    options: Object,
  },
});
