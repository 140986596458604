
import Vue from "vue";
export default Vue.extend({
  name: "ImageOrIcon",
  props: {
    entity: Object,
    title: String,
    fieldClass: String,
    fieldStyle: String,
    fields: Array,
    options: Object,
  },
  methods: {
    decode: decodeURIComponent,
  },
  computed: {
    image(): string {
      return `url(${this.decode(this.entity[this.title])})`;
    },
  },
});
